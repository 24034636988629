<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import zendesk from '@/../../shared/js/zendesk.js';

export default {
  name: 'SkillsgapBaseVue',
  mixins: [zendesk],
  created(){
    this.suppressZendeskAll();
  },
};
</script>

<style scoped>

</style>
